import axios from 'axios'

export default function $axios (options) {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: '/'
    })

    instance.interceptors.request.use(
      config => {
        config.headers['Content-Type'] = 'application/json;charset=UTF-8'
        return config
      },
      error => {
        console.error(error)
        return Promise.reject(error)
      }
    )

    // response 拦截器
    instance.interceptors.response.use(
      response => {
        const code = response.status
        if (code !== 200) {
          console.error(response.message)
          return Promise.reject(new Error(response.message))
        } else {
          if (response.data.code === 200) {
            if (response.data.totalCount === 0) {
              return response.data.content
            } else {
              return response.data
            }
          }
          return Promise.reject(new Error(response.data.msg))
        }
      },
      error => {
        let code = 0
        try {
          code = error.response.data.status
        } catch (e) {
          if (error.toString().indexOf('Error: timeout') !== -1) {
            return Promise.reject(error)
          }
        }
        if (code) {
          const errorMsg = error.response.data.message
          if (errorMsg !== undefined) {
            console.error(error)
          }
        } else {
          console.error('接口请求失败')
        }
        return Promise.reject(error)
      }
    )

    // 请求处理
    instance(options).then(res => {
      resolve(res)
      return false
    }).catch(error => {
      reject(error)
    })
  })
}
