<template>
  <div>
    <div class="top">
      <div class="result">
        <h3>查询结果</h3>
        <p>
          {{ msg }}
        </p>
      </div>
      <div class="info">
        <div>
          <p style="margin-bottom: 10px">松下蓄电池（沈阳）有限公司</p>
          <p class="pp">公司地址：沈阳经济技术开发区浑河二十街17号</p>
          <p class="pp">营业部门：024-62786227、024-62786256</p>
          <p class="pp">客户服务：024-62786291</p>
          <p class="pp">技术支持：024-62786223</p>
          <p class="pp">公司网址：http://www.panasonicbattery.cn</p>
        </div>
      </div>
      <img src="../assets/bgimg1.png" alt="" />
    </div>
    <div class="middle">
      <div class="btn">
        <p @click="show()">查看产品型号分类</p>
      </div>
      <img src="../assets/bgimg2.png" alt="" />
    </div>
    <div class="floor">
      <img src="../assets/bgimg3.png" alt="" />
    </div>
    <div class="zhezhao" :class="{'show':showTag}">
      <img src="../assets/x.png" alt="" @click="unshow()" style="width: 30px;margin: 20% auto 5%;">
      <div class="dialog">
        <img src="../assets/bg_1.jpg" alt="" />
        <div style="width: 100%;height: calc(55%);overflow-y: scroll;">
          <table class="table" border="0" cellspacing="0">
            <tr style="background-color: #32b1b8">
              <td rowspan="2">用途<br>Application</td>
              <td rowspan="2" colspan="2">分类标准<br>Classify Criterion</td>
              <td colspan="2">电池系列<br>Product Series</td>
            </tr>
            <tr style="background-color: #32b1b8">
              <td>阻燃V0槽壳<br>(Flame Retardant)</td>
              <td>HB 槽壳<br>(HB)</td>
            </tr>
            <tr>
              <td rowspan="2">主电源<br>Main Power</td>
              <td colspan="2">循环普通 Cycle Standard</td>
              <td></td>
              <td>LC-R(N)</td>
            </tr>
            <tr>
              <td colspan="2">循环长寿命 Cycle Long Life</td>
              <td></td>
              <td>LC-CA;LC-XC</td>
            </tr>

            <tr>
              <td rowspan="3">备用电源<br>Standby Power</td>
              <td rowspan="7">浮充期待寿命<br>Expected trickle life</td>
              <td>5年(Years)</td>
              <td>LC-V; UP-VW</td>
              <td>UP-RW</td>
            </tr>
            <tr>
              <td>10年(Years)</td>
              <td>LC-P; UP-PW</td>
              <td>LC-Y</td>
            </tr>
            <tr>
              <td>12年(Years)</td>
              <td>LC-PH</td>
              <td></td>
            </tr>
            <tr>
              <td rowspan="4">风力发电变桨系统<br>For pitch backup systems in wind turbines</td>
              <td>15年(Years)</td>
              <td>LC-MH; LC-E</td>
              <td></td>
            </tr>
            <tr>
              <td>17年(Years)</td>
              <td>LC-QA</td>
              <td></td>
            </tr>
            <tr>
              <td>5年(Years)</td>
              <td>LC-WTV</td>
              <td></td>
            </tr>
            <tr>
              <td>10年(Years)</td>
              <td>LC-WTP</td>
              <td></td>
            </tr>
            <tr>
              <td>储能 Energy storage series</td>
              <td colspan="2">太阳光发电蓄能 Solar Energy</td>
              <td></td>
              <td>LC-T</td>
            </tr>
          </table>
          <div style="margin: 0 auto;padding: 0 10px;font-size: 2vw;">
          注：产品采用优质ABS槽壳，阻燃材料符合UL94V-0标准，HB槽壳符合UL94HB标准。
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="content">
    </div> -->
  </div>
</template>

<script>
import axios from "@/http/axios";

export default {
  name: "HelloWorld",
  data() {
    return {
      msg: "",
      showTag: false
    };
  },
  mounted: function () {
    this.getCode();
  },
  methods: {
    getCode() {
      let url = window.location.href;
      // let root = "http://localhost:13000";
      // let root = url.split("/fw/")[0] + "/api";
      let root = url.split("/index")[0] + "/api";
      let code = url.split("code=")[1].split("#/")[0];
      let decodeUrl = root + "/decode";
      let params = {
        "code": code,
        "longitude": 0,
        "latitude": 0,
      }
      axios({
        url: decodeUrl,
        method: "post",
        data: params,
      }).then((res) => {
        console.log(res);
        if(res.scanCount == 1 || res.scanCount > 5) {
          this.msg = res.code + " " + res.content;
        }else{
          this.msg = res.code + " 该防伪码已查询" + res.scanCount + "次，首次查询时间是" + res.firstScanTime + "，" + res.content;
        }
      });
    },
    show() {
      this.showTag = true;
    },
    unshow() {
      this.showTag = false;
    }
  },
};
</script>
<style>
img {
  display: block;
  width: 100%;
}
.top {
  background-size: 100%;
  width: 100%;
}
.result {
  width: 80%;
  margin: 0 auto;
  height: auto;
  color: white;
  font-size: 4vw;
  padding-top: 20vw;
  padding-left: 10vw;
  position: absolute;
}
.info {
  width: 78%;
  margin: 0 auto;
  height: auto;
  padding-top: 90vw;
  padding-left: 10vw;
  position: absolute;
}
.info > div > p {
  font-size: 3.6vw;
}

.pp {
  padding-bottom: 5px;
}
.middle {
  background-size: 100%;
  width: 100%;
}
.btn {
  color: #fff;
  width: 100%;
  padding-top: 4vw;
  text-align: center;
  position: absolute;
}
.btn > p {
  text-align: center;
  font-size: 4.5vw;
}
.floor {
  background-size: 100%;
  width: 100%;
  position: absolute;
}
.zhezhao {
  position: fixed;
  left: 0px;
  top: 0px;
  background: #00000088;
  width: 100%;
  height: 100%;
  display: none;
}
.show {
  display: block;
}
.dialog {
  position: relative;
  background: #fff;
  width: 92%;
  height: 80%;
  margin: 0 auto;
}
.dialog > img {
  opacity: 1 !important;
}
.table {
  width: 95%;
  margin: 0 auto;
  text-align: center;
}
table {
  border-left: 1px #ccc solid;
  border-top: 1px #ccc solid;
}
td {
  border-bottom: 1px #ccc solid;
  border-right: 1px #ccc solid;
  font-size: 2vw;
}
body:before {
  width: 100%;
  height: 100%;
  content: ' ';
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  background: #749df5;
}
</style>
